jQuery(function () {
    $('.hamburger').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('mobile-menu-opened');
    });
});

const app = (() => {

    var rafTimer;
    const stickyBreakPoint = $('body').data('sticky') ?? 250;

    if (stickyBreakPoint < 0) {
        return;
    }

    window.onscroll = function (event) {
        cancelAnimationFrame(rafTimer);
        rafTimer = requestAnimationFrame(toggleHeaderFloating);
    };

    function toggleHeaderFloating() {
        if (window.scrollY > stickyBreakPoint) {
            document.body.classList.add('sticky');
        } else {
            document.body.classList.remove('sticky');
        }
    }
})();

